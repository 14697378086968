import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import type { AppState } from '@/shared/redux/AppState';
import { Auth } from '@/shared/utils/auth/auth';

import type { CustomUser } from '../auth/typings';

/**
 * This hook returns all auth-related information and should
 * be used instead of `useAuth0`.
 *
 * It includes auth0 information and provides additional helpers
 * and overrides.
 */
export const useAuth = () => {
  const auth0 = useAuth0<CustomUser>();
  const currentUser = useSelector((state: AppState) => state.user);

  return useMemo(
    () => {
      const auth = new Auth(auth0, currentUser);

      return {
        // spreading auth0 helpers for convenience all components
        // connecting to `useAuth` also have access to `useAuth0` information
        ...auth0,
        // next we spread our custom auth instance which contains helpers and
        // overrides e.g. for `logout` or `isAuthenticated`
        ...auth,
      };
    },
    // HEADSUP: exhaustive-deps disabled to add auth0.isAuthenticated as dependency
    [
      auth0,
      currentUser,
      // see https://shippio.atlassian.net/browse/ONE-1635
      // TODO: if this does not work, then remove this line again and reinvestigate!
      auth0.isAuthenticated,
    ],
  );
};
