export const TRADE_DIRECTIONS = ['export', 'import'] as const;

export const SCHEDULE_METHOD = {
  VESSEL: 'vessel',
  CALENDAR: 'calendar',
} as const;

export const SCHEDULE_CALENDAR_DATE_TYPES = {
  ETD: 'etd',
  ETA: 'eta',
  CUTOFFDATE: 'cutOffDate',
  DELIVERY_DATE: 'deliveryDate',
} as const;

export const SCHEDULE_CALENDAR_DATE_OPTIONS = {
  import: { eta: 'eta', deliveryDate: 'deliveryDate' },
  export: { etd: 'etd', eta: 'eta' },
} as const;

export const CONTAINER_TYPES = [
  '_20ft',
  '_40ft',
  '_20ft_flat',
  '_40ft_flat',
  '_20ft_open_top',
  '_40ft_open_top',
  '_20ft_reefer',
  '_40ft_reefer',
  '_40ft_hc',
] as const;

export const DRAYAGE_TYPES = [
  '_20ft_2axles',
  '_40ft_2axles',
  '_20ft_3axles',
  '_40ft_3axles',
] as const;

export const TRUCK_TYPES = [
  'consolidated',
  'self_loader',
  'lowbed_semi',
  'high_flatbed',
  'any_2t',
  'powergate_2t',
  'refrigerated_2t',
  'crane_2t',
  'wing_2t',
  'flat_2t',
  'any_4t',
  'powergate_4t',
  'refrigerated_4t',
  'crane_4t',
  'wing_4t',
  'flat_4t',
  'any_10t',
  'powergate_10t',
  'refrigerated_10t',
  'crane_10t',
  'wing_10t',
  'flat_10t',
  'flat_trailer_20t',
  'wing_trailer_20t',
] as const;

export const CARGO_TYPES = {
  fcl: 'FCL',
  lcl: 'LCL',
} as const;

export const CUSTOMS_HANDLING = {
  real_time: 'real_time',
  deferment: 'deferment',
  consultation_required: 'consultation_required',
  prepaid: 'prepaid',
  pay_on_behalf: 'pay_on_behalf',
} as const;

export const INSURANCE_HANDLING = {
  self_coverage: 'self_coverage',
  shippio_covers: 'shippio_covers',
  consultation_required: 'consultation_required',
  no_insurance: 'no_insurance',
} as const;

type Incoterm =
  | 'EXW'
  | 'FCA'
  | 'FAS'
  | 'FOB'
  | 'CFR'
  | 'CPT'
  | 'CIF'
  | 'CIP'
  | 'DAT'
  | 'DPU'
  | 'DAP'
  | 'DDP';

// Type of object to store incoterms per trade direction
type IncotermsMap = {
  import: Incoterm[];
  export: Incoterm[];
};

export const INCOTERMS: IncotermsMap = {
  import: [
    'EXW',
    'FCA',
    'FAS',
    'FOB',
    'CFR',
    'CPT',
    'CIF',
    'CIP',
    'DAT',
    'DPU',
  ],
  export: [
    'FCA',
    'FAS',
    'FOB',
    'CFR',
    'CPT',
    'CIF',
    'CIP',
    'DAT',
    'DPU',
    'DAP',
    'DDP',
  ],
};

export const SIMULATION_MODES = {
  quote: 'quote',
  shipmentRequest: 'shipment-request',
  confirmShipment: 'confirm-shipment',
} as const;

export const TRANSPORTATION_MODES = {
  AIR: 'AIR',
  OCEAN: 'OCEAN',
} as const;

// From PM
// In Export, Dxx Incoterms should always show both LT Domestic and Overseas
export const PLACES_DISPLAY_RULES = {
  export: {
    domestic: [
      'FCA',
      'FOB',
      'FAS',
      'CIF',
      'CFR',
      'CPT',
      'CIP',
      'DAP',
      'DPU',
      'DDP',
      'DAT',
    ],
    overseas: ['DPU', 'DDP', 'DAP', 'DAT'],
  },
  import: {
    domestic: ['EXW', 'FCA', 'FOB', 'FAS', 'CIF', 'CFR', 'CPT', 'CIP'],
    overseas: ['EXW'],
  },
} as const;

// Missing values that we are required to throw an error
export const MISSING_VALUES = {
  incoterms: null,
  POD: null,
  POL: null,
} as const;

// Local Storage Keys
export const STORAGE_TRADE_DIRECTION =
  'quote-simulation-trade-direction' as const;
export const STORAGE_TRANSPORTATION_MODE =
  'quote-simulation-transportation-mode' as const;
export const STORAGE_INCOTERMS = 'quote-simulation-incoterms' as const;
export const STORAGE_ROUTE = 'quote-simulation-route' as const;
export const STORAGE_CARGO_TYPE = 'quote-simulation-cargo-type' as const;
export const STORAGE_ASSIGNEE = 'quote-simulation-assignee' as const;

export const STORAGE_DEFAULTS = {
  STORAGE_TRADE_DIRECTION: 'import',
  STORAGE_CARGO_TYPE: CARGO_TYPES.fcl,
  STORAGE_TRANSPORTATION_MODE: {
    import: TRANSPORTATION_MODES.OCEAN,
    export: TRANSPORTATION_MODES.OCEAN,
  },
  STORAGE_INCOTERMS: { import: null, export: null },
  STORAGE_ROUTE: {
    import: { POL: null, POD: null },
    export: { POL: null, POD: null },
  },
} as const;

export const ANY_PORT = {
  id: 'any',
} as const;

// In the future more conditions will be added - e.g. 'packaging',
// hence using a list of strings
export const ADDITIONAL_REQUEST_DISABLED_CONDITIONS = {
  import: {
    CIF: ['insurance'],
    CIP: ['insurance'],
  },
  export: {
    FCA: ['insurance'],
    FAS: ['insurance'],
    FOB: ['insurance'],
    CFR: ['insurance'],
    CPT: ['insurance'],
  },
} as const;

// Although this is located in simulations const file, this adjustment should
// be used app wide, and may be adjusted in the future
export const TOTAL_CALCULATION_ERROR = 10e-10;
