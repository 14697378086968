import type { AnyAction } from '@reduxjs/toolkit';

import type { pushEvent, removeEvent } from './actions';
import type { LoggableUIEvent } from './loggableUIEvent';
import * as types from './types';

type UIEventsState = LoggableUIEvent[]

/**
 * Creates a function to filter old or used events.
 * @param {string} id an event ID to be removed from the list
 * @returns {(event) => boolean} a function that tests if a given event is valid
 * (not too old) and has an ID other than the ID provided.
 */
const validItemsWithoutId = (
  id: string,
) => (event: LoggableUIEvent) => event.isValid && event.id !== id;

export const uiEvents = (state: UIEventsState = [], action: AnyAction): UIEventsState => {
  switch (action.type) {
    case types.UIEVENTS_PUSH: {
      const { event } = action.payload as ReturnType<typeof pushEvent>['payload'];
      return [...state, event];
    }

    case types.UIEVENTS_REMOVE: {
      const { id } = action.payload as ReturnType<typeof removeEvent>['payload'];
      return state.filter(validItemsWithoutId(id));
    }

    default:
      return state;
  }
};
