import { ACTIVE_TAB_LOCAL_INVOICES } from '@/utils/constants/invoices';

import { STORAGE_DASHBOARD_ASSIGNEE } from '../../../AppMain/utils/constants/dashboard';
import { ACTIVE_TAB_LOCAL_STORAGE_KEY } from '../../../utils/constants/quote';
import {
  STORAGE_ASSIGNEE,
  STORAGE_CARGO_TYPE,
  STORAGE_INCOTERMS,
  STORAGE_ROUTE,
  STORAGE_TRADE_DIRECTION,
  STORAGE_TRANSPORTATION_MODE,
} from '../../../utils/constants/simulations';
import { DRAFT_CHAT_MESSAGES } from './chat';
import { SHIPMENT_LIST_TRADE_DIRECTION } from './shipments';
import { SIDEBAR_COLLAPSE_LOCAL_STORAGE_KEY } from './sidebar';

export const NON_STAFF_X_AUTH_ROLE = 'Authentication' as const;

export const USER_TYPE = {
  staff: 'Staff',
  customerUser: 'CustomerUser',
} as const;

// The hardcoded values are the ones that are already no longer used and it's kept here to clean up the client local storage when logout.
export const LOCAL_STORAGE_REMOVABLE_KEYS = [
  ACTIVE_TAB_LOCAL_STORAGE_KEY,
  DRAFT_CHAT_MESSAGES,
  'shipment-list-active-filters',
  SHIPMENT_LIST_TRADE_DIRECTION,
  'shipment-list-filters-v2',
  'shipment-list-active-view',
  SHIPMENT_LIST_TRADE_DIRECTION,
  'shipment-list-sort',
  SIDEBAR_COLLAPSE_LOCAL_STORAGE_KEY,
  STORAGE_ASSIGNEE,
  STORAGE_DASHBOARD_ASSIGNEE,
  STORAGE_CARGO_TYPE,
  STORAGE_INCOTERMS,
  STORAGE_ROUTE,
  STORAGE_TRADE_DIRECTION,
  STORAGE_TRANSPORTATION_MODE,
  'quote-chat-walkthrough',
  ACTIVE_TAB_LOCAL_INVOICES,
] as const;
