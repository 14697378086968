import {
  ResponsibilityTypeEnum,
  BookingStatusFilterEnum,
  MilestoneTypeEnum,
  TradeDirectionEnum,
} from '@/__codegen__/graphql';

import type { NumberAdd } from '../typescript';

export const TRADE_DIRECTIONS = ['export', 'import'] as const;
export type TRADE_DIRECTIONS = typeof TRADE_DIRECTIONS[number];

export const MAJOR_MILESTONES = {
  [TradeDirectionEnum.Import]: [
    MilestoneTypeEnum.Collection,
    MilestoneTypeEnum.Departure,
    MilestoneTypeEnum.Arrival,
    MilestoneTypeEnum.CustomsClearanceArrival,
    MilestoneTypeEnum.Delivery,
  ],
  [TradeDirectionEnum.Export]: [
    MilestoneTypeEnum.Collection,
    MilestoneTypeEnum.CustomsClearanceDeparture,
    MilestoneTypeEnum.CutOff,
    MilestoneTypeEnum.Departure,
    MilestoneTypeEnum.Arrival,
    MilestoneTypeEnum.Delivery,
  ],
} as const;
export type MilestonePositionValues =
  (typeof MILESTONE_POSITION)[keyof typeof MILESTONE_POSITION];

export const MILESTONE_POSITION = {
  PAST: 'past',
  ACTIVE: 'active',
  PSEUDO_ACTIVE: 'pseudo-active',
  FUTURE: 'future',
  NA: 'not-applicable',
} as const;

export const MILESTONE_STATUS = {
  COMPLETED: 'completed',
  NOT_COMPLETE: 'not_complete',
  WARNING: 'warning',
  CRITICAL: 'critical',
  DELAY_WARNING: 'delay_warning',
  DELAY_CRITICAL: 'delay_critical',
  FREE_TIME: 'free-time',
} as const;

export const MILESTONE_DETAILED_REASON_KEYS = {
  OVERBOOKING: 'overbooking',
  VESSEL_CHANGE: 'vessel_change',
  OMIT: 'omit',
  CONGESTION: 'congestion',
  BAD_WEATHER: 'bad_weather',
  DOCS_IN_TRANSIT: 'docs_in_transit',
  INCOMPLETE_DOC: 'incomplete_doc',
  CUSTOMS_SCREENING: 'customs_screening',
  TRAFFIC_JAM: 'traffic_jam',
  SOCIAL_CONDITIONS: 'social_conditions',
  PRODUCTION_ISSUES: 'production_issues',
  AWAITING_SHIPPER_RESPONSE: 'awaiting_shipper_response',
  VESSEL_UNDECIDED: 'vessel_undecided',
  DELIVERY_ADJUSTMENT: 'delivery_adjustment',
  UNLOADING_SITE_CONDITIONS: 'unloading_site_conditions',
  AWAITING_INQUIRY_RESPONSE: 'awaiting_inquiry_response',
  AWAITING_CONFIRMATION: 'awaiting_confirmation',
  FULL_PARKING: 'full_parking',
  CONTAINER_DAMAGE: 'container_damage',
  TROUBLE_ON_BOARD: 'trouble_on_board',
  EQUIPMENT_ISSUE: 'equipment_issue',
  PORT_ACCIDENT: 'port_accident',
  SHIPPING_COMPANY_ISSUES: 'shipping_company_issues',
  PORT_STRIKE: 'port_strike',
  NATURAL_DISASTERS: 'natural_disasters',
  CARGO_DAMAGE: 'cargo_damage',
  PICKUP_ADJUSTMENT: 'pickup_adjustment',
  UNSPECIFIED: 'unspecified',
} as const;

export const DETAILED_REASONS_PER_MILESTONE = {
  preparation: [
    'unspecified',
    'docs_in_transit',
    'incomplete_doc',
    'social_conditions',
    'production_issues',
    'awaiting_shipper_response',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'natural_disasters',
  ],
  collection: [
    'unspecified',
    'overbooking',
    'bad_weather',
    'docs_in_transit',
    'incomplete_doc',
    'traffic_jam',
    'social_conditions',
    'production_issues',
    'awaiting_shipper_response',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'full_parking',
    'natural_disasters',
    'pickup_adjustment',
  ],
  packing: [
    'unspecified',
    'docs_in_transit',
    'incomplete_doc',
    'social_conditions',
    'awaiting_shipper_response',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'natural_disasters',
  ],
  vanning: [
    'unspecified',
    'bad_weather',
    'docs_in_transit',
    'incomplete_doc',
    'social_conditions',
    'awaiting_shipper_response',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'natural_disasters',
  ],
  carrying_in: [
    'unspecified',
    'overbooking',
    'docs_in_transit',
    'incomplete_doc',
    'traffic_jam',
    'social_conditions',
    'awaiting_shipper_response',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'full_parking',
    'natural_disasters',
  ],
  customs_clearance_departure: [
    'unspecified',
    'docs_in_transit',
    'incomplete_doc',
    'customs_screening',
    'social_conditions',
    'awaiting_shipper_response',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
  ],
  cut_off: ['unspecified', 'overbooking'],
  departure: [
    'unspecified',
    'overbooking',
    'vessel_change',
    'omit',
    'congestion',
    'bad_weather',
    'docs_in_transit',
    'incomplete_doc',
    'social_conditions',
    'vessel_undecided',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'trouble_on_board',
    'equipment_issue',
    'port_accident',
    'shipping_company_issues',
    'port_strike',
    'natural_disasters',
  ],
  transship: [
    'unspecified',
    'overbooking',
    'vessel_change',
    'omit',
    'congestion',
    'bad_weather',
    'docs_in_transit',
    'incomplete_doc',
    'vessel_undecided',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'trouble_on_board',
    'equipment_issue',
    'port_accident',
    'shipping_company_issues',
    'port_strike',
    'natural_disasters',
  ],
  arrival: [
    'unspecified',
    'vessel_change',
    'omit',
    'congestion',
    'bad_weather',
    'docs_in_transit',
    'incomplete_doc',
    'vessel_undecided',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'trouble_on_board',
    'equipment_issue',
    'port_accident',
    'port_strike',
    'natural_disasters',
    'cargo_damage',
  ],
  customs_clearance_arrival: [
    'unspecified',
    'docs_in_transit',
    'incomplete_doc',
    'customs_screening',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
  ],
  devanning: [
    'unspecified',
    'bad_weather',
    'docs_in_transit',
    'incomplete_doc',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'full_parking',
    'natural_disasters',
    'cargo_damage',
    'pickup_adjustment',
  ],
  unpacking: [
    'unspecified',
    'docs_in_transit',
    'incomplete_doc',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'natural_disasters',
    'cargo_damage',
    'pickup_adjustment',
  ],
  delivery: [
    'unspecified',
    'vessel_change',
    'bad_weather',
    'docs_in_transit',
    'incomplete_doc',
    'customs_screening',
    'traffic_jam',
    'delivery_adjustment',
    'unloading_site_conditions',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'full_parking',
    'container_damage',
    'trouble_on_board',
    'equipment_issue',
    'port_accident',
    'natural_disasters',
    'cargo_damage',
    'pickup_adjustment',
  ],
} as const;

export const PAGINATION_PER_PAGE = 50 as const;

export const PRE_SHIPMENT_STATUS = 'pre_shipment' as const;

// Local Storage key
export const SHIPMENT_LIST_TRADE_DIRECTION = 'shipment-list-trade-direction' as const;

export const TIME_CONSCIOUS_MILESTONES = [
  'collection',
  'delivery',
  'arrival',
  'departure',
  'transship',
] as const;

export const MILESTONE_TYPES_WITH_PORTS = [
  'arrival',
  'departure',
  'transship',
] as const;

export const TASK_STATUS = {
  COMPLETED: 'completed',
  NOT_COMPLETE: 'not_complete',
  WARNING: 'warning',
  CRITICAL: 'critical',
} as const;

export const MILESTONES_WITH_DRAYAGE_STATUS = [
  'collection',
  'delivery',
] as const;

export const CARGO_TYPE = {
  FCL: 'FCL',
  LCL: 'LCL',
  AIR: 'AIR',
} as const;

export const REGEX_FOR_DXX_INCOTERMS = /^D/;

export const FILE_GROUP_WIDTHS = {
  status: 4,
  category: 28,
  filename: 27,
  user: 18,
  datetime: 19,
  actions: 4,
  edit: (27 + 18 + 19 + 4) as NumberAdd<NumberAdd<NumberAdd<27, 18>, 19>, 4>,
  categoryMode: (28 + 27 + 18 + 19 + 4) as NumberAdd<
    NumberAdd<NumberAdd<NumberAdd<28, 27>, 18>, 19>,
    4
  >,
} as const;
export type FILE_GROUP_WIDTHS =
  (typeof FILE_GROUP_WIDTHS)[keyof typeof FILE_GROUP_WIDTHS];

export const TASK_GROUP_WIDTHS = {
  status: 5,
  category: 60,
  datetime: 25,
  actions: 10,
} as const;

export const TASK_TYPES = {
  standard: 'standard',
  documentRequest: 'document_request',
} as const;

export const FILE_CATEGORIES = {
  miscDocuments: [
    'miscellaneous',
    'product_information',
    'other_custom_laws',
    'other_custom_clearance_materials',
  ],
  tradeDocuments: [
    'arrival_notice',
    'bill_of_lading',
    'customs_clearance_permit',
    'certificate_of_origin',
    'packing_list',
    'shipping_advice',
    'shipping_instructions',
    'insurance',
    'invoice',
  ],
} as const;

export const FILE_CATEGORIES_PER_DIRECTION = {
  import: {
    miscDocuments: [
      'miscellaneous',
      'product_information',
      'other_custom_laws',
      'other_custom_clearance_materials',
    ],
    tradeDocuments: [
      'arrival_notice',
      'bill_of_lading',
      'customs_clearance_permit',
      'certificate_of_origin',
      'packing_list',
      'shipping_advice',
      'insurance',
      'invoice',
    ],
  },
  export: {
    miscDocuments: [
      'miscellaneous',
      'product_information',
      'other_custom_clearance_materials',
    ],
    tradeDocuments: [
      'bill_of_lading',
      'customs_clearance_permit',
      'certificate_of_origin',
      'packing_list',
      'shipping_instructions',
      'insurance',
      'invoice',
    ],
  },
} as const;

export const FILE_SECTIONS = {
  icpDocuments: 'icpDocuments',
  partnerTeamDocuments: 'partnerTeamDocuments',
  miscDocuments: 'miscDocuments',
  tradeDocuments: 'tradeDocuments',
} as const;

export const NSS_FILTER_OPTIONS = {
  all: 'all',
  shippio: ResponsibilityTypeEnum.Shippio,
  non_shippio: ResponsibilityTypeEnum.NonShippio,
} as const;

export const ALERT_FILTER_OPTIONS = {
  all: 'all',
  critical: 'critical',
  warning: 'warning',
  noAlerts: 'noAlerts',
} as const;

export const RESPONSIBILITY_TYPES = {
  shippio: 'shippio',
  non_shippio: 'non_shippio',
} as const;

// 'import_request' is replaced by 'pre_shipment'(Feb 2021)
// Todo: Remove import_request when all 'import_request' are gone
export const SHIPMENT_STATUS = {
  import_request: 'import_request',
  pre_shipment: 'pre_shipment',
  completed: 'completed',
};

export const SHIPMENT_STATUS_FILTER_OPTIONS = {
  all: 'all',
  active_only: 'active_only',
  cancelled_only: 'cancelled_only',
} as const;

export const SHIPMENT_LIST_DELIVERY_HEADERS = [
  'product',
  'voyageInfo',
] as const;

export const SHIPMENT_LIST_DOWNLOAD_TYPES = {
  export_default: 'export_default',
  import_default: 'import_default',
  import_delivery: 'import_delivery_management',
  export_container_list: 'export_container_list',
} as const;

export const SHIPMENT_LIST_EXPORT_DOWNLOAD_TYPES = {
  shipments: 'shipments',
  containers: 'containers',
} as const;

export const SHIPMENT_LIST_VIEWS = {
  default: 'default',
  delivery: 'delivery',
} as const;

export const SHIPMENT_LIST_VIEW_SIZES = {
  full: 'full',
  minimized: 'minimized',
} as const;

export const SHIPMENT_LIST_VIEW_WIDTHS = {
  [SHIPMENT_LIST_VIEWS.default]: {
    [SHIPMENT_LIST_VIEW_SIZES.full]: {
      // Do not fully add up to 100% to keep vertical alignment with the truncated text
      route: 22,
      cargoContents: 22,
      status: 14,
      milestones: 30,
      miscellaneous: 10,
    },
    [SHIPMENT_LIST_VIEW_SIZES.minimized]: {
      route: 100,
      cargoContents: 0,
      status: 0,
      milestones: 0,
      miscellaneous: 0,
    },
  },
  [SHIPMENT_LIST_VIEWS.delivery]: {
    [SHIPMENT_LIST_VIEW_SIZES.full]: {
      // Do not fully add up to 100% to keep vertical alignment with the truncated text
      status: 16,
      product: 16,
      voyageInfo: 16,
      deliveryMilestone: 41,
      miscellaneous: 8,
    },
    [SHIPMENT_LIST_VIEW_SIZES.minimized]: {
      status: 50,
      product: 50,
      voyageInfo: 0,
      deliveryMilestone: 0,
      miscellaneous: 0,
    },
  },
} as const;

export const MILESTONE_EVENT_ROW_WIDTHS = {
  [SHIPMENT_LIST_VIEW_SIZES.full]: {
    deliveryCargoPlace: 44,
    deliveryDateTimeBookingStatus: 56,
  },
  [SHIPMENT_LIST_VIEW_SIZES.minimized]: {
    deliveryCargoPlace: 0,
    deliveryDateTimeBookingStatus: 0,
  },
} as const;

export const VIEW_FILTER_OPTIONS = {
  import: [SHIPMENT_LIST_VIEWS.default, SHIPMENT_LIST_VIEWS.delivery],
  export: [SHIPMENT_LIST_VIEWS.default],
} as const;

export const DRAYAGE_RESERVATION_STATUS = [
  'not_reserved',
  'reserved',
  'needs_rescheduling',
] as const;

export const CUSTOM_ASSIGNED_TO_OPTIONS = {
  allAssignees: 'all_assignees',
  noAssignees: 'no_assignees',
} as const;

export const CUSTOM_ASSIGNED_TO_OPTION_TYPENAME = 'CustomShipmentListAssigneeToTypename';

export const ASSIGNED_TO_URL_PARAMS_TYPENAME_MAP = {
  user: 'CustomerUser',
  group: 'UserGroup',
} as const;

// Every filter must have a default value (even with null) for batch number counter
export const DEFAULT_SHIPMENT_RICH_FILTERS = {
  shipmentStatus: 'all',
  nssFilter: 'all',
  alertStatuses: 'all',
  labelFilter: [],
  shipperId: [],
  consigneeId: [],
  podId: [],
  polId: [],
  deliveryAddressId: null,
  assignedTo: [
    {
      id: CUSTOM_ASSIGNED_TO_OPTIONS.allAssignees,
    },
  ],
  bookingStatus: null,
  customerTeamId: null,
  productIds: [],
  assignedPartnerTeamIds: [CUSTOM_ASSIGNED_TO_OPTIONS.allAssignees],
};

export const BOOKING_STATUS_FILTER_OPTIONS = {
  arranged: BookingStatusFilterEnum.Arranged,
  needs_reservation: BookingStatusFilterEnum.NeedsReservation,
} as const;

export const SHIPMENT_FOCUS_OPTIONS = {
  chat: 'chat',
  scheduleHistory: 'schedule-history',
  assignMembers: 'assign-members',
} as const;

export const CONTAINER_TYPES_MAP = {
  _20ft: '_20ft',
  _40ft: '_40ft',
  _20ft_flat: '_20ft_flat',
  _40ft_flat: '_40ft_flat',
  _20ft_open_top: '_20ft_open_top',
  _40ft_open_top: '_40ft_open_top',
  _20ft_reefer: '_20ft_reefer',
  _40ft_reefer: '_40ft_reefer',
  _40ft_hc: '_40ft_hc',
} as const;

export const CONTAINER_TYPES = [
  CONTAINER_TYPES_MAP._20ft,
  CONTAINER_TYPES_MAP._40ft,
  CONTAINER_TYPES_MAP._20ft_flat,
  CONTAINER_TYPES_MAP._40ft_flat,
  CONTAINER_TYPES_MAP._20ft_open_top,
  CONTAINER_TYPES_MAP._40ft_open_top,
  CONTAINER_TYPES_MAP._20ft_reefer,
  CONTAINER_TYPES_MAP._40ft_reefer,
  CONTAINER_TYPES_MAP._40ft_hc,
] as const;

export const ACCURACY_LEVELS = {
  normal: 'normal',
  temporary: 'temporary',
  low: 'low',
} as const;

export const ACCURACY_POSITION_TYPES = {
  nextToEventTitle: 'nextToEventTitle',
  nextToMilestoneDateTime: 'nextToMilestoneDateTime',
} as const;

export const MILESTONE_TYPES = {
  preparation: 'preparation',
  collection: 'collection',
  packing: 'packing',
  vanning: 'vanning',
  carrying_in: 'carrying_in',
  customs_clearance_departure: 'customs_clearance_departure',
  cut_off: 'cut_off',
  departure: 'departure',
  transship: 'transship',
  arrival: 'arrival',
  customs_clearance_arrival: 'customs_clearance_arrival',
  devanning: 'devanning',
  unpacking: 'unpacking',
  delivery: 'delivery',
} as const;

export const CAR_CONTACT_INFO_LEAVE_DELAY_AFTER_COPIED = 1000; // 1 second
export const CAR_CONTACT_INFO_REOPEN_DELAY_AFTER_COPIED = 3000; // 3 second

export const INSURANCE_HANDLING_OPTIONS = [
  'self_coverage',
  'shippio_covers',
  'consultation_required',
  'no_insurance',
] as const;

export const CUSTOMS_HANDLING_OPTIONS = [
  'real_time',
  'deferment',
  'consultation_required',
  'prepaid',
  'pay_on_behalf',
] as const;

export const NSS_AUTO_TRACKING_MILESTONES = {
  [MILESTONE_TYPES.departure]: MILESTONE_TYPES.departure,
  [MILESTONE_TYPES.arrival]: MILESTONE_TYPES.arrival,
  [MILESTONE_TYPES.transship]: MILESTONE_TYPES.transship,
} as const;

export const TRANSPORTATION_MODES = {
  ocean: 'ocean',
  air: 'air',
} as const;

export const PLACE_TYPES = {
  [MILESTONE_TYPES.collection]: MILESTONE_TYPES.collection,
  [MILESTONE_TYPES.delivery]: MILESTONE_TYPES.delivery,
} as const;

export const DRAYAGE_CHASSIS_TYPES = {
  _20ft_2axles: '_20ft_2axles',
  _20ft_3axles: '_20ft_3axles',
  _40ft_2axles: '_40ft_2axles',
  _40ft_3axles: '_40ft_3axles',
} as const;

export const PLACES_MILESTONES = [
  MILESTONE_TYPES.collection,
  MILESTONE_TYPES.delivery,
] as const;

export const TRANSIT_MILESTONES = [
  MILESTONE_TYPES.departure,
  MILESTONE_TYPES.transship,
  MILESTONE_TYPES.arrival,
] as const;
export const MUST_EXIST_MILESTONES = [
  MILESTONE_TYPES.preparation,
  MILESTONE_TYPES.departure,
  MILESTONE_TYPES.arrival,
] as const;

export const NEED_TWO_EVENT_MILESTONES_TYPES = [
  MILESTONE_TYPES.packing,
  MILESTONE_TYPES.unpacking,
  MILESTONE_TYPES.transship,
] as const;

// HACK: In the case of draftMilestone,
// eventName cannot be obtained because it is not stored in back-end.
// Refer to back-end
// https://github.com/shippio/back-end/blob/4d554a868753d0cf645e0e4a2baa93e21b5a1e83/monolith/app/models/milestone_event.rb#L159
export const EVENT_NAMES_FOR_MILESTONE_TYPE = {
  [MILESTONE_TYPES.preparation]: ['Cargo Ready 予定日'],
  [MILESTONE_TYPES.collection]: ['バンニング予定日'],
  [MILESTONE_TYPES.packing]: ['梱包業者への到着予定日', '梱包完了予定日'],
  [MILESTONE_TYPES.vanning]: ['バンニング予定日'],
  [MILESTONE_TYPES.carrying_in]: ['搬入予定日'],
  [MILESTONE_TYPES.customs_clearance_departure]: ['通関予定日'],
  [MILESTONE_TYPES.cut_off]: [{ LCL: 'CFS CUT', FCL: 'CY CUT' }],
  [MILESTONE_TYPES.departure]: ['ETD'],
  [MILESTONE_TYPES.transship]: ['ETA', 'ETD'],
  [MILESTONE_TYPES.arrival]: ['ETA'],
  [MILESTONE_TYPES.customs_clearance_arrival]: ['通関予定日'],
  [MILESTONE_TYPES.devanning]: ['デバンニング予定日'],
  [MILESTONE_TYPES.unpacking]: ['開梱業者への到着予定日', '開梱完了予定日'],
  [MILESTONE_TYPES.delivery]: ['納品予定日'],
} as const;

export const AUTO_TRACKING_STATUSES = {
  CARGO: 'cargo',
  VESSEL: 'vessel',
  DISABLED: 'disabled',
  NOT_SUPPORTED: 'not_supported',
  PREPARING: 'initial_cleansing_in_progress',
} as const;

export const AUTO_TRACKING_REQUIRED_INFORMATION_ENUM = {
  MBL_NUMBER: 'mbl',
  BOOKING_NUMBER: 'bkg',
  CONTAINER_NUMBER: 'ctn',
  VESSEL_NAME: 'vessel_name',
  VOYAGE_NUMBER: 'voyage_number',
  CARRIER: 'carrier',
  POL: 'pol',
  POD: 'pod',
} as const;

export const REQUIRED_FOR_CARGO_TRACKING = [
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.MBL_NUMBER,
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.BOOKING_NUMBER,
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.CONTAINER_NUMBER,
] as const;

export const REQUIRED_FOR_VESSEL_TRACKING = [
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.VESSEL_NAME,
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.VOYAGE_NUMBER,
] as const;

export const MANDATORY_FOR_AUTO_TRACKING = [
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.CARRIER,
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.POL,
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.POD,
] as const;

export const MANDATORY_FOR_LCL_AUTO_TRACKING = [
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.CARRIER,
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.POL,
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.POD,
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.VESSEL_NAME,
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.VOYAGE_NUMBER,
] as const;

export const PACKAGE_TYPE_LABELS = {
  BA: 'BA - Barrel',
  BE: 'BE - Bundle',
  BG: 'BG - Bag',
  BK: 'BK - Basket',
  BL: 'BL - Bale',
  BN: 'BN - Bale',
  BR: 'BR - Bar',
  BX: 'BX - Box',
  CA: 'CA - Can',
  CG: 'CG - Cage',
  CH: 'CH - Chest',
  CK: 'CK - Cask',
  CL: 'CL - Coil',
  CN: 'CN - Container',
  CO: 'CO - Carboy',
  CP: 'CP - Carboy',
  CR: 'CR - Crate',
  CS: 'CS - Case',
  CT: 'CT - Carton',
  CX: 'CX - Can',
  CY: 'CY - Cylinder',
  DJ: 'DJ - DemiJohn',
  DP: 'DP - DemiJohn',
  DR: 'DR - Drum',
  FB: 'FB - Fibre',
  FL: 'FL - Flask',
  FR: 'FR - Frame',
  FT: 'FT - Flexible',
  HD: 'HD - HEAD',
  HG: 'HG - Hogeshead',
  IN: 'IN - Ingot',
  JG: 'JG - Jug',
  JR: 'JR - Jar',
  KG: 'KG - Keg',
  LG: 'LG - Log',
  LZ: 'LZ - Logs',
  MT: 'MT - Mat',
  NE: 'NE - Unpacked',
  NT: 'NT - Net',
  PA: 'PA - Packet',
  PC: 'PC - Parcel',
  PE: 'PE - Pen',
  PG: 'PG - Plate',
  PI: 'PI - Pipe',
  PK: 'PK - Package',
  PL: 'PL - Pail',
  PP: 'PP - Pallet',
  PS: 'PS - Piece',
  PU: 'PU - Tray',
  PY: 'PY - Plates',
  PZ: 'PZ - Planks',
  RL: 'RL - Reel',
  RO: 'RO - Roll',
  SA: 'SA - Sack',
  SB: 'SB - Slab',
  SF: 'SF - Set',
  SI: 'SI - Skid',
  SK: 'SK - Skeleton',
  SS: 'SS - Steel',
  ST: 'ST - Sheet',
  SV: 'SV - Steel',
  SZ: 'SZ - Sheet',
  TB: 'TB - Tub',
  TI: 'TI - Tierce',
  TN: 'TN - Tin',
  VA: 'VA - Vat',
  VG: 'VG - Bulk',
  VK: 'VK - Vanpack',
  VL: 'VL - Bulk',
  VO: 'VO - Bulk',
  VQ: 'VQ - Bulk',
  VR: 'VR - Bulk',
  VY: 'VY - Bulk',
  WD: 'WD - Wooden',
  ZZ: 'ZZ - Other',
} as const;
