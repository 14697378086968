import TagManager from 'react-gtm-module';

import type { GetCurrentUserLoginInfoItem } from '@/shared/gql/query/GetCurrentUserLoginInfo';

export const setUpGTM = () => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID as string,
    auth: process.env.REACT_APP_GTM_AUTH as string,
    preview: process.env.REACT_APP_GTM_PREVIEW as string,
    dataLayer: {
    },
  };

  TagManager.initialize(tagManagerArgs);
};

export const setGTMUser = (user: GetCurrentUserLoginInfoItem) => {
  const args = {
    dataLayer: {
      userId: user.id,
      companyId: user.loginUserTeamInfo?.company.id,
      companyName: user.loginUserTeamInfo?.company.name,
    },
  };
  TagManager.dataLayer(args);
};
