import type { EnrichmentPlugin, Event } from '@amplitude/analytics-types';

/**
 * This plugin is used to override the Default Tracking Event.
 * While the default event is comprehensive, it is inconvenient for handling in reports,
 * so we are overriding the EventType.
 * In the future, implementing CustomEvent as needed will eliminate the need to capture
 * the default PageView event.
 */

export const pageViewTrackingEnrichment = (): EnrichmentPlugin => ({
  name: 'page-view-tracking-enrichment',
  type: 'enrichment',
  setup: async () => undefined,
  execute: async (event: Event) => {
    // override default page view events
    if (event.event_type !== '[Amplitude] Page Viewed') {
      return event;
    }

    const updatedEvent = setEventParamsFromQuery(event);
    updatedEvent.event_type += `: ${getScreenNameFromPath(window.location.pathname)}`;

    return updatedEvent;
  },
});

function setEventParamsFromQuery(event: Event): Event {
  const queryParams = new URLSearchParams(window.location.search);
  const tabName = queryParams.get('tab');

  return {
    ...event,
    event_properties: {
      ...event.event_properties,
      ...(tabName ? { tab_name: tabName } : {}),
      event_category: 'page_view',
    },
  };
}

function getScreenNameFromPath(path: string): string {
  const segments = path.split('/').filter(Boolean);

  switch (segments[0]) {
    case 'dashboard':
      return 'Dashboard';
    case 'shipment':
      return 'Shipment Details';
    case 'shipments':
      return determineShipmentScreenName(segments);
    case 'in-transit-product-items':
      return 'In-transit Products';
    case 'quote':
      return 'Quote';
    case 'confirm-shipment':
      return 'Confirm Shipment';
    case 'order-confirmed':
      return 'Order Confirmed';
    case 'invoices':
      return 'Invoices';
    case 'analytics':
      return 'Analytics';
    case 'products':
      return determineProductScreenName(segments);
    case 'places':
      return determinePlaceScreenName(segments);
    case 'partners':
      return determinePartnerScreenName(segments);
    case 'settings':
      return determineSettingsScreenName(segments);
    default:
      return 'Undefined Screen';
  }
}

function determineShipmentScreenName(segments: string[]): string {
  if (segments.length === 1) {
    return 'Shipments List';
  } else {
    return 'Shipment List With Details';
  }
}

function determineProductScreenName(segments: string[]): string {
  if (segments.length === 1) {
    return 'Products List';
  } else if (segments.includes('create')) {
    return 'Create Product';
  } else if (segments.includes('edit')) {
    return 'Edit Product';
  } else {
    return 'Product Information';
  }
}

function determinePlaceScreenName(segments: string[]): string {
  if (segments.length === 1) {
    return 'Places List';
  } else if (segments.includes('create')) {
    return 'Create Place';
  } else if (segments.includes('edit')) {
    return 'Edit Place';
  } else {
    return 'Place Information';
  }
}

function determinePartnerScreenName(segments: string[]): string {
  if (segments.length === 1) {
    return 'Partners List';
  } else if (segments.includes('create')) {
    return 'Create Partner';
  } else if (segments.includes('edit')) {
    return 'Edit Partner';
  } else {
    return 'Partner Information';
  }
}

function determineSettingsScreenName(segments: string[]): string {
  if (segments.length === 1) {
    return 'Settings Top';
  } else if (segments.includes('members')) {
    return 'Manage Members';
  } else if (segments.includes('mfa')) {
    return 'Manage MFA Setting';
  } else if (segments.includes('user-groups')) {
    return segments.length === 2 ? 'User Groups List' : 'Edit User Group';
  } else {
    return 'Settings Information';
  }
}
