import { SidebarContainer } from '@/shared/components/Sidebar/SidebarContainer';
import { SidebarProvider } from '@/shared/context/SidebarContext';

type Props = {
  authenticated: boolean;
}

export const AppFallback = ({ authenticated }: Props) => (
  authenticated && (
    <SidebarProvider>
      <SidebarContainer />
      <div className="topbar" />
    </SidebarProvider>
  )
);
