import { ReactComponent as Rafiki } from '@/assets/images/rafiki.svg';
import { Box, Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Maintenance = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Stack spacing={2}>
        <Typography
          color="primary"
          align="center"
          fontSize={24}
          fontWeight="bold"
        >
          Shippioクラウドサービスはメンテナンス中です
        </Typography>
        <Typography
          color="primary"
          align="center"
          fontSize={24}
          fontWeight="bold"
        >
          We're under maintenance
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <Rafiki />
        </Box>
        <Typography
          color="primary"
          align="center"
          fontSize={16}
          fontWeight="bold"
        >
          詳細については{' '}
          <a
            href="mailto:support@shippio.zohodesk.jp "
            target="_blank"
            rel="noreferrer"
          >
            support@shippio.zohodesk.jp
          </a>
          までお問い合わせください。
        </Typography>
        <Typography
          color="primary"
          align="center"
          fontSize={16}
          fontWeight="bold"
        >
          Please contact{' '}
          <a
            href="mailto:support@shippio.zohodesk.jp "
            target="_blank"
            rel="noreferrer"
          >
            support@shippio.zohodesk.jp
          </a>{' '}
          for details.
        </Typography>
      </Stack>
    </Container>
  );
};
export default Maintenance;
