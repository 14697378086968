import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ReactComponent as AddressBook } from '@/assets/icons/LineAwesome/address-book.svg';
import { ReactComponent as Insights } from '@/assets/icons/LineAwesome/chart-bar-solid.svg';
import { ReactComponent as CogSolid } from '@/assets/icons/LineAwesome/cog-solid.svg';
import { ReactComponent as Cubes } from '@/assets/icons/LineAwesome/cubes-solid.svg';
import { ReactComponent as InvoiceDollar } from '@/assets/icons/LineAwesome/file-invoice-dollar-solid.svg';
import { ReactComponent as LuggageCartSolid } from '@/assets/icons/LineAwesome/luggage-cart-solid.svg';
import { ReactComponent as MapMarkedAltSolid } from '@/assets/icons/LineAwesome/map-marked-alt-solid.svg';
import { ReactComponent as Paste } from '@/assets/icons/LineAwesome/paste-solid.svg';
import { ReactComponent as ShipSolid } from '@/assets/icons/LineAwesome/ship-solid.svg';
import { ReactComponent as Container } from '@/assets/icons/custom/container.svg';
import { ReactComponent as DashboardIcon } from '@/assets/icons/custom/dashboard-icon.svg';
import { ReactComponent as Question } from '@/assets/icons/custom/question.svg';
import { INVOICE_PATHS } from '@/routes/invoices';
import { SIMULATION_CUSTOMER_USER_PATHS } from '@/routes/simulation';
import {
  type MenuItem,
  SidebarMenu,
} from '@/shared/components/Sidebar/SidebarMenu/SidebarMenu';
import type { AppState } from '@/shared/redux/AppState';
import { useLogDirectAccessSidebarClick } from '@/shared/utils/hooks/ActivityLogging/useLogDirectAccessSidebarClick';
import { useAuth } from '@/shared/utils/hooks/useAuth';
import {
  ACTIVE_TAB_LOCAL_STORAGE_KEY,
  QUOTE_TABS,
} from '@/utils/constants/quote';

import { Sidebar } from './Sidebar';

const getActiveQuoteTab = () =>
  localStorage.getItem(ACTIVE_TAB_LOCAL_STORAGE_KEY) || QUOTE_TABS.quote;

/**
 * General the Quote URL depending on the last active Quote tab
 */
const generateQuoteUrl = ({
  isStaff,
  teamId,
}: { isStaff: boolean; teamId: string }) => {
  if (!isStaff) {
    // If non-internal mode, then use the value from local storage
    // to determine which tab should be the link for the quote menu item
    switch (getActiveQuoteTab()) {
      case 'savedQuote':
        return SIMULATION_CUSTOMER_USER_PATHS.savedQuote;

      case 'quoteRequests':
        return SIMULATION_CUSTOMER_USER_PATHS.requestQuote;

      default:
        return SIMULATION_CUSTOMER_USER_PATHS.default;
    }
  }

  // Don't require persistence for internal mode
  return `/team/${teamId}/quote`;
};

const SidebarMenuContainer = () => {
  const {
    'P_FO_FDN_ALL_access-analytics-dashboard_20231001':
      canAccessInsightsDashboard,
    'T_FO_FE_container-list-phase1_20240719': showContainerListMenu,
  } = useFlags();
  const { t } = useTranslation(['translation']);
  const { isStaffUser, isPaidServiceType, isWarehouseUser } = useAuth();
  const { pathname } = useLocation();

  const isDisableItems = isStaffUser();

  const teamId = useSelector((state: AppState) => state.user.teamId);
  // TODO: Replace with generated type
  const isStaff = isStaffUser();
  const isPaid = isPaidServiceType();

  const [pushUIEvent] = useLogDirectAccessSidebarClick();

  /**
   * Handler that dispatches direct access UI event
   * when user clicks on menu items
   */
  const handleOnClick = useCallback(
    (link: string) => {
      if (pushUIEvent) {
        if (link !== 'quote') {
          pushUIEvent(link);
        }

        if (getActiveQuoteTab() === QUOTE_TABS.quote) {
          pushUIEvent('quote-tab');
        }
      }
    },
    [pushUIEvent],
  );

  const menuItems: MenuItem[][] = [
    [
      isPaid && {
        type: 'link',
        title: t('translation:navigation.dashboard'),
        to: '/dashboard',
        disabled: isDisableItems,
        icon: <DashboardIcon style={{ height: `${14 / 16}rem` }} />,
        isActive: isStaff ? false : pathname.includes('/dashboard'),
        onClick: () => handleOnClick('dashboard'),
      },
      {
        type: 'link',
        title: t('translation:navigation.shipments'),
        to: {
          pathname: '/shipments',
          state: { from: 'sidebar' },
        },
        disabled: isDisableItems,
        icon: <ShipSolid />,
        isActive: isStaff ? false : pathname.includes('/shipment'),
        onClick: () => handleOnClick('shipment'),
      },
      !isWarehouseUser() &&
        showContainerListMenu && {
          type: 'link',
          title: t('translation:navigation.containers'),
          to: '/containers',
          disabled: isDisableItems,
          icon: (
            <Container
              style={{ height: `${18 / 16}rem`, width: `${18 / 16}rem` }}
            />
          ),
          isActive: isStaff ? false : pathname.includes('/containers'),
        },
      isPaid && {
        type: 'link',
        title: t('translation:navigation.inTransitProductItems'),
        to: '/in-transit-product-items',
        disabled: isDisableItems,
        icon: <Cubes />,
        isActive: isStaff
          ? false
          : pathname.includes('/in-transit-product-items'),
      },
      isPaid &&
        canAccessInsightsDashboard && {
          type: 'link',
          title: t('translation:navigation.insights'),
          to: '/insights',
          disabled: isDisableItems,
          icon: <Insights />,
          isActive: isStaff ? false : pathname.includes('/insights'),
        },
      isPaid && {
        type: 'link',
        title: t('translation:navigation.quote'),
        to: generateQuoteUrl({ isStaff, teamId }),
        disabled: isDisableItems,
        icon: <Paste />,
        isActive:
          pathname.includes('/quote') || pathname.includes('/shipment-request'),
        onClick: () => handleOnClick('quote'),
      },
      isPaid && {
        type: 'link',
        title: t('translation:navigation.invoices'),
        disabled: isDisableItems,
        to: INVOICE_PATHS.digitalForward,
        isActive: pathname.includes('/invoices'),
        icon: <InvoiceDollar />,
      },
    ],
    [
      // Menu items below the divider
      isPaid && {
        type: 'link',
        title: t('translation:navigation.productManagement'),
        to: '/products',
        disabled: isDisableItems,
        icon: <LuggageCartSolid />,
      },
      isPaid && {
        type: 'link',
        title: t('translation:navigation.customerManagement'),
        to: '/partners',
        disabled: isDisableItems,
        icon: <AddressBook />,
      },
      isPaid && {
        type: 'link',
        title: t('translation:navigation.locationManagement'),
        to: '/places',
        disabled: isDisableItems,
        icon: <MapMarkedAltSolid />,
      },
      {
        type: 'link',
        title: t('translation:navigation.setting'),
        to: '/settings',
        disabled: isDisableItems,
        icon: <CogSolid />,
      },
      {
        type: 'button',
        title: t('translation:navigation.support'),
        disabled: isDisableItems,
        icon: <Question />,
      },
    ],
  ];

  return (
    <SidebarMenu
      menuItems={menuItems.map((subList) => subList.filter(Boolean))}
    />
  );
};

export const SidebarContainer = () => (
  <Sidebar>
    <SidebarMenuContainer />
  </Sidebar>
);
