import { datadogRum } from '@datadog/browser-rum';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { createRoot } from 'react-dom/client';
import '../shared/index.scss';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { setUpAmplitude } from '@/lib/amplitude';
import { setUpDataDogLogs, setUpDatadogRum } from '@/lib/datadog';
import { setUpGTM } from '@/lib/gtm';
import { Auth0ProviderWithHistory } from '@/shared/provider/Auth0ProviderWithHistory';
import { CurrentUserProvider } from '@/shared/provider/CurrentUserProvider';

import App from './App';
import { store } from './redux/store';
import { ApolloWrapper } from '../graphql/ApolloWrapper';
import './i18n';
import * as serviceWorker from '../serviceWorker';

setUpAmplitude();
setUpGTM();
setUpDatadogRum();
setUpDataDogLogs();

const renderApp = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    options: {
      inspectors: [
        {
          type: 'flag-used',
          name: 'dd-inspector',
          method: (flagKey, flagDetail) => {
            datadogRum.addFeatureFlagEvaluation(flagKey, flagDetail.value);
          },
        },
      ],
    },
  });

  const container = document.getElementById('root');
  const root = createRoot(container as HTMLElement);
  root.render(
    <React.StrictMode>
      <LDProvider>
        <Provider store={store}>
          <BrowserRouter>
            <Auth0ProviderWithHistory>
              <ApolloWrapper>
                <CurrentUserProvider>
                  <App />
                </CurrentUserProvider>
              </ApolloWrapper>
            </Auth0ProviderWithHistory>
          </BrowserRouter>
        </Provider>
      </LDProvider>
    </React.StrictMode>,
  );
};

renderApp();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
