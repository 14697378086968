import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink, useNavigate } from 'react-router-dom';

import { TradeDirectionEnum } from '@/__codegen__/graphql';
import ThemeTooltip from '@/shared/components/common/ThemeTooltip/ThemeTooltip';
import { useSidebar } from '@/shared/context/SidebarContext';
import { browserStorage } from '@/utils/browserStorage';
import { invariant } from '@/utils/helpers/invariant';

export type MenuItem = {
  type: 'link' | 'button';
  title: string;
  to?:
    | string
    | {
        pathname: string;
        state: {
          from: string;
        };
      };
  disabled?: boolean;
  icon: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
};

const generateShipmentsPathFromStorage = async () => {
  const lastAccessedShipmentsTradeDirection =
    (await browserStorage.getItem('lastAccessedShipmentsTradeDirection')) ||
    TradeDirectionEnum.Import;
  const lastAccessedShipmentsSearchParamsString = await browserStorage.getItem(
    `${lastAccessedShipmentsTradeDirection}LastAccessedShipmentsSearchParamsString`,
  );
  return lastAccessedShipmentsSearchParamsString || '';
};

type Props = {
  menuItems: MenuItem[][];
};

export const SidebarMenu = ({ menuItems }: Props) => {
  const { isCollapsed } = useSidebar();
  const navigate = useNavigate();

  const listItemsGenerator = (items: MenuItem[]) =>
    items.map(({ type, title, icon, disabled, to, isActive, onClick }) => {
      if (type === 'button') {
        return (
          <ThemeTooltip
            arrow
            title={isCollapsed ? title : ''}
            placement="right"
            key={title}
          >
            <ListItemButton
              component="a"
              className="sidebar__nav-list__item"
              onClick={() =>
                window.open(
                  'https://shippioinc.notion.site/Shippio-AnyCargo-3a443ea386154a8f985727b9f418dc74',
                  '_blank',
                )
              }
              disabled={disabled}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText>{title}</ListItemText>
            </ListItemButton>
          </ThemeTooltip>
        );
      }

      if (typeof to !== 'string' && to?.pathname === '/shipments') {
        return (
          <ThemeTooltip
            arrow
            title={isCollapsed ? title : ''}
            placement="right"
            key={title}
          >
            <ListItemButton
              component="a"
              className={`sidebar__nav-list__item${
                disabled ? ' sidebar__nav-list__item--is-disabled' : ''
              } ${isActive ? 'active' : ''}`}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={async () => {
                const params = await generateShipmentsPathFromStorage();
                navigate(`/shipments?${params}`);
              }}
              disabled={disabled}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText>{title}</ListItemText>
              <div className="sidebar__active-border" />
            </ListItemButton>
          </ThemeTooltip>
        );
      }

      return (
        <ThemeTooltip
          arrow
          title={isCollapsed ? title : ''}
          placement="right"
          key={title}
        >
          <ListItem
            // React router dom link component does not have disabled capability
            // https://github.com/ReactTraining/react-router/issues/1082#issuecomment-160348163
            className={`sidebar__nav-list__item${
              disabled ? ' sidebar__nav-list__item--is-disabled' : ''
            } ${isActive ? 'active' : ''}`}
            component={NavLink as React.ElementType}
            to={to}
            onClick={() => {
              if (onClick) {
                onClick();
              }
            }}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{title}</ListItemText>
            <div className="sidebar__active-border" />
          </ListItem>
        </ThemeTooltip>
      );
    });

  invariant(menuItems[0] && menuItems[1], 'SidebarMenu: No MenuItems found!');

  const listItemsAbove = listItemsGenerator(menuItems[0]);
  const listItemsBelow = listItemsGenerator(menuItems[1]);

  return (
    <>
      <List component="nav" aria-label="primary" className="sidebar__nav-list">
        {listItemsAbove}
      </List>
      <Divider className="sidebar__divider" />
      <List component="nav" aria-label="primary" className="sidebar__nav-list">
        {listItemsBelow}
      </List>
    </>
  );
};
