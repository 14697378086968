import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { pushEvent } from '@/shared/redux/uiEvents/actions';
import {
  VIEW_SHIPMENT_LIST,
  VIEW_QUOTE_SIMULATION_PATTERN,
} from '@/shared/utils/constants/activity-log-types';

export const useLogDirectAccessSidebarClick = () => {
  const dispatch = useDispatch();

  /**
   * Push Ui direct access event to redux depending
   * on the link clicked.
   */
  const pushUIEvent = useCallback((link: string) => {
    let uiEvent = null;

    switch (link) {
      case 'shipment':
        uiEvent = {
          eventTypes: [VIEW_SHIPMENT_LIST.eventType],
          trigger: VIEW_SHIPMENT_LIST.triggers.directAccess,
        };
        break;

      case 'quote-tab':
        uiEvent = {
          eventTypes: [VIEW_QUOTE_SIMULATION_PATTERN.eventType],
          trigger: VIEW_QUOTE_SIMULATION_PATTERN.triggers.directAccess,
          options: {
            [VIEW_QUOTE_SIMULATION_PATTERN.options.viewLayout.key]:
              // sidebar link will never link to quote
              VIEW_QUOTE_SIMULATION_PATTERN.options.viewLayout.values.normal,
          },
        };
        break;

      default:
        break;
    }

    if (uiEvent) {
      dispatch(pushEvent(uiEvent));
    }
  }, [dispatch]);

  return [pushUIEvent];
};
