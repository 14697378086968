/**
 * To return corresponding value of the given key stored in cookie
 */
export const getValueFromCookie = (key: string): string | undefined => {
  const cookies = document.cookie.split('; ');
  let result: string | undefined;

  cookies.forEach((cookie) => {
    const [_key, value] = cookie.split('=');
    if (_key === key && value) {
      result = decodeURIComponent(value);
    }
  });

  return result;
};

export const clearAllCookies = () => {
  document.cookie.split(';').forEach((cookie) => {
    const [name] = cookie.split('=');
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  });
};
