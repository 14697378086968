import localforage from 'localforage';
import z from 'zod';

import { TradeDirectionEnum } from '@/__codegen__/graphql';

const storageSchema = z.object({
  lastAccessedShipmentsTradeDirection: z.nativeEnum(TradeDirectionEnum),
  importLastAccessedShipmentsSearchParamsString: z.string(),
  exportLastAccessedShipmentsSearchParamsString: z.string(),
});
type BrowserStorage = z.infer<typeof storageSchema>;

export const browserStorage = Object.freeze({
  getItem: async <K extends keyof BrowserStorage>(key: K): Promise<BrowserStorage[K] | null> => {
    try {
      const value = await localforage.getItem(key);
      if (value === null) {
        return null;
      }
      return storageSchema.shape[key].parse(value) as BrowserStorage[K];
    } catch (error) {
      localforage.removeItem(key);
      window.rollbar.error(`Error getting ${key} from browserStorage: ${error}`);
      return null;
    }
  },
  setItem: <K extends keyof BrowserStorage>(key: K, value: BrowserStorage[K]) => {
    try {
      localforage.setItem(key, value);
    } catch (error) {
      window.rollbar.error(`Error setting ${key} from browserStorage: ${error}`);
    }
  },
  clear: () => {
    try {
      localforage.clear();
    } catch {
      window.rollbar.error('Error clearing storage');
    }
  },
  removeItem: (key: keyof BrowserStorage) => {
    try {
      localforage.removeItem(key);
    } catch (error) {
      window.rollbar.error(`Error removing ${key} from browserStorage: ${error}`);
    }
  },
} as const);
