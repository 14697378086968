import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const SERVICE_NAME = 'front-office' as const;
// TODO need to move github action secret to env
const CLIENT_TOKEN = 'pub32687bbecc95f08746ae17f6f8abdd98' as const;
const APPLICATION_ID = 'a042aa2b-8333-4e36-8929-b8af311f405b' as const;

const isDatadogActivated = process.env.REACT_APP_DATADOG_ENABLED === 'true';

export const setUpDatadogRum = () => {
  if (isDatadogActivated) {
    datadogRum.init({
      applicationId: APPLICATION_ID,
      clientToken: CLIENT_TOKEN,
      service: SERVICE_NAME,
      env: process.env.REACT_APP_ENV,
      version: process.env.REACT_APP_COMMIT_HASH,
      trackUserInteractions: true,
      trackLongTasks: true,
      trackResources: true,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      traceSampleRate: 100,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        process.env.REACT_APP_GRAPHQL_API as string,
        process.env.REACT_APP_PDF_API as string,
        process.env.REACT_APP_IMPORT_NSS_API as string,
        process.env.REACT_APP_IMPORT_PRODUCT_API as string,
        process.env.REACT_APP_DATA_EXPORT_API as string, // TODO: need to refactor env
      ],
      enableExperimentalFeatures: ['feature_flags'],
    });
    datadogRum.startSessionReplayRecording();
  }
};

export const setUserDatadog = (id: string) => {
  if (isDatadogActivated) {
    datadogRum.setUser({
      id,
    });
  }
};

export const setUpDataDogLogs = () => {
  if (isDatadogActivated) {
    datadogLogs.init({
      clientToken: CLIENT_TOKEN,
      env: process.env.REACT_APP_ENV,
      service: SERVICE_NAME,
      version: process.env.REACT_APP_COMMIT_HASH,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
    });
  }
};
