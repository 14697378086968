import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { CurrentUser, LanguageEnum } from '@/__codegen__/graphql';

import { CHAT_ASSIGNEE_SELECTIONS, STORAGE_ASSIGNED_CHAT } from '../utils/constants/chat';
import type { AllPropertiesOptional, ValueOf } from '../utils/typescript';
import { getLocalStorageObject } from '../utils/utils';

export type CurrentUserState = AllPropertiesOptional<Omit<CurrentUser, 'company' | 'customerTeams'> & {
  company: { id: string, name: string }
  team: { id: string, name: string, serviceType?: { type: string, value: number } | null},
  teamId: string,
  chatAssigneeSelection: ValueOf<typeof CHAT_ASSIGNEE_SELECTIONS>,
}>;

// TODO: unify `CurrentUserState` and `UserState` since both are "current session" data
const initialState: CurrentUserState = {
  id: null,
  company: null,
  email: null,
  firstName: null,
  lastName: null,
  language: null,
  loginUserType: null,
  loginUserCompanyType: null,
  team: null,
  teamId: null,
  threeWayFunctionDisabled: null, // Only exposed to FOfW
  unreadChannelCount: null,
  unreadAssignedChannelCount: null,
  chatAssigneeSelection: null,
};

const getLocalChatAssignee = (): CurrentUserState['chatAssigneeSelection'] => {
  const assigneeSelection = getLocalStorageObject<{
    assigneeSelection: keyof typeof CHAT_ASSIGNEE_SELECTIONS
  }>(STORAGE_ASSIGNED_CHAT)?.assigneeSelection;
  if (assigneeSelection && CHAT_ASSIGNEE_SELECTIONS[assigneeSelection]) {
    return assigneeSelection;
  }
  return CHAT_ASSIGNEE_SELECTIONS.allChats;
};

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser: (
      state,
      action: PayloadAction<CurrentUserState>,
    ) => ({
      ...state,
      id: action.payload.id,
      company: action.payload.company,
      email: action.payload.email,
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      language: action.payload.language,
      loginUserType: action.payload.loginUserType,
      loginUserCompanyType: action.payload.loginUserCompanyType,
      team: action.payload.team,
      teamId: action.payload.teamId,
      chatAssigneeSelection: getLocalChatAssignee(),
      unreadChannelCount: action.payload.unreadChannelCount,
      unreadAssignedChannelCount: action.payload.unreadAssignedChannelCount,
    }),

    editName: (
      state,
      action: PayloadAction<{ lastName: string, firstName: string }>,
    ) => ({
      ...state,
      lastName: action.payload.lastName,
      firstName: action.payload.firstName,
    }),

    editTeamId: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      teamId: action.payload,
    }),

    editUnreadChannelCount: (
      state,
      action: PayloadAction<number>,
    ) => ({
      ...state,
      unreadChannelCount: action.payload,
    }),

    editUnreadAssignedChannelCount: (
      state,
      action: PayloadAction<number>,
    ) => ({
      ...state,
      unreadAssignedChannelCount: action.payload,
    }),

    editChatAssigneeSelection: (
      state,
      action: PayloadAction<ValueOf<typeof CHAT_ASSIGNEE_SELECTIONS>>,
    ) => ({
      ...state,
      chatAssigneeSelection: action.payload,
    }),

    editLanguage: (
      state,
      action: PayloadAction<LanguageEnum>,
    ) => ({
      ...state,
      language: action.payload,
    }),
  },
});

export const {
  setCurrentUser,
  editName,
  editTeamId,
  editUnreadChannelCount,
  editUnreadAssignedChannelCount,
  editChatAssigneeSelection,
  editLanguage,
} = currentUserSlice.actions;
