import type { Auth0ContextInterface } from '@auth0/auth0-react';

import { ampli } from '@/__ampli__';
import {
  LoginUserCompanyTypeEnum,
  LoginUserTypeEnum,
} from '@/__codegen__/graphql';
import { apolloClient } from '@/graphql/ApolloClient';
import type { CurrentUserState } from '@/shared/redux/currentUserSlice';
import { browserStorage } from '@/utils/browserStorage';

import { LOCAL_STORAGE_REMOVABLE_KEYS } from '../constants/auth';
import { clearAllCookies, getValueFromCookie } from './helpers';
import { type CustomUser, LoginUserRole } from './typings';

const MINIMUM_PAID_SERVICE_TYPE_VALUE = 10;
export const STAFF_SERVICE_TYPE_VALUE = 99;

/**
 * This class acts as the central source of truth for accessing user related
 * auth information. It's helper methods are made accessible via `useAuth` hook.
 *
 * The `Auth` class can also be used independently outside the context
 * of react or if `useAuth` is not available (e.g. see ApolloProvider).
 *
 * In the future it may also provide access to permissions.
 * @see https://www.notion.so/shippioinc/202308_Difference-Between-FO-FOfW-FOfF-d574dcd931094a498ead24e51d28881c
 */
export class Auth {
  /**
   * Provided by Auth0 after login.
   */
  private auth0: Auth0ContextInterface<CustomUser>;
  /**
   * Represents the user of the current session.
   * (may be undefined if no session exists)
   */
  private user?: CurrentUserState;

  /**
   * Whether or not the user is authenticated.
   * Because this depends on whether we have an auth0 session (non-staff)
   * or cookie session (shippio staff), we use it instead of auth0's isAuthenticated.
   */
  public isAuthenticated: boolean;

  constructor(
    auth0: Auth0ContextInterface<CustomUser>,
    user?: CurrentUserState,
  ) {
    this.auth0 = auth0;
    this.user = user;
    this.isAuthenticated =
      (this.auth0.isAuthenticated || !!Auth.getAuthFromCookie().token) &&
      !!this.user;
  }
  /**
   * If the BO has set a cookie, this will return the auth info set inside the cookie.
   * It is used as an alternative means for shippio staff to authenticate our app.
   *
   * TODO: remove once BO switched to auth0
   */
  public static getAuthFromCookie = () => ({
    token: getValueFromCookie(
      `X-Auth-${process.env.REACT_APP_COOKIE_NAME_SUFFIX}`,
    ),
    role: getValueFromCookie(
      `X-Role-${process.env.REACT_APP_COOKIE_NAME_SUFFIX}`,
    ),
  });

  /**
   * this will return the currently used session token.
   * It is either an auth0 session (non-staff) or cookie session (shippio staff).
   *
   * If a staff token exists, it will take precedence over the auth0 token!
   */
  getToken = async () => {
    // internal mode session (staff)
    if (this.isStaffUser()) {
      return Auth.getAuthFromCookie().token;
    }

    // auth0 session
    if (this.auth0.isAuthenticated) {
      let tokenClaims = await this.auth0.getIdTokenClaims();
      if (!tokenClaims?.__raw) {
        // HACK: sometimes the getIdTokenClaims return undefined,
        // so we wait access_token to be generated then try again.
        // https://github.com/auth0/auth0-react/issues/402#issuecomment-1240277139
        await this.auth0.getAccessTokenSilently();
        tokenClaims = await this.auth0.getIdTokenClaims();
      }
      return tokenClaims?.__raw;
    }

    return undefined;
  };

  getLoginUserRole = () => {
    if (this.isStaffUser()) return LoginUserTypeEnum.Staff;
    if (this.isCustomerUser()) return LoginUserTypeEnum.CustomerUser;
    if (this.isWarehouseUser()) return LoginUserTypeEnum.CustomerPartnerUser;
    if (this.isForwarderUser() || this.isSubsidiaryUser())
      return LoginUserTypeEnum.PartnerUser;
    return undefined;
  };

  isCustomerUser = () =>
    this.user?.loginUserType === LoginUserTypeEnum.CustomerUser;
  isStaffUser = () => Auth.getAuthFromCookie().role === LoginUserRole.Staff;
  isWarehouseUser = () =>
    this.user?.loginUserType === LoginUserTypeEnum.CustomerPartnerUser;
  isSubsidiaryUser = () =>
    this.user?.loginUserType === LoginUserTypeEnum.PartnerUser &&
    this.user?.loginUserCompanyType ===
      LoginUserCompanyTypeEnum.LocalSubsidiary;
  isForwarderUser = () =>
    this.user?.loginUserType === LoginUserTypeEnum.PartnerUser &&
    this.user?.loginUserCompanyType === LoginUserCompanyTypeEnum.Forwarder;
  /**
   * A partner user is any user that is neither shippio staff nor a customer.
   * This includes forwarders, subsidiaries...
   *
   * HEADSUP: warehouses are considered "ICPs" and NOT included, use isWarehouseUser() instead!
   */
  isPartnerUser = () =>
    !!this.user &&
    !(this.isCustomerUser() || this.isStaffUser() || this.isWarehouseUser());

  isPaidServiceType = () =>
    !!this.user?.team?.serviceType &&
    this.user?.team.serviceType.value >= MINIMUM_PAID_SERVICE_TYPE_VALUE;

  /**
   * Performs several cleanups before logging out the user.
   * We use it as an override to auth0's logout.
   */
  logout = async () => {
    if (typeof localStorage !== 'undefined') {
      // TODO: better to use a whitelist, see https://shippio.atlassian.net/browse/TECH-288
      LOCAL_STORAGE_REMOVABLE_KEYS.map((key) => localStorage.removeItem(key));
    }

    await browserStorage.clear();

    // used because BO internal mode uses cookies
    // TODO: remove once BO switched to auth0
    clearAllCookies();

    apolloClient.stop();
    await apolloClient.clearStore();

    this.auth0.logout({ returnTo: window.location.origin });
    ampli.client.reset();
  };
}
