import {
  type ApolloClient, ApolloLink, ApolloProvider, type NormalizedCacheObject,
} from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';

import { useAuth } from '@/shared/utils/hooks/useAuth';
import { getCurrentLanguage } from '@/shared/utils/utils';

import { apolloClient } from './ApolloClient';
import {
  createErrorLink,
  createHeadersAuthLink,
  createMainLink,
} from './apolloLinks';

type Props = {
  children: React.ReactNode;
};

export const ApolloWrapper = ({ children }: Props) => {
  const auth = useAuth();
  const auth0 = useAuth0();
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  /**
   * If an auth token is present at the time of mount, this creates a client
   * whose subscription link is able to connect to AppSync.
   * If an auth token is **not** present on mount, because user is not yet logged in,
   * this creates an "unauthorized" client able to login, but unable to connect to AppSync
   * Once a token is available after login, this creates a new client that **is** able to
   * connect to AppSync.
   */
  useEffect(() => {
    (async () => {
      // either cookie token for staff or auth0 token for customers etc.
      const token = await auth.getToken();

      if (token) {
        // Update the Apollo Client
        apolloClient.setLink(ApolloLink.from([
          createHeadersAuthLink(token!, auth.isStaffUser()),
          createErrorLink(),
          createMainLink(token!),
        ]));
        setClient(apolloClient);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  if (!auth0.isAuthenticated && !auth0.isLoading && !auth.isStaffUser()) {
    auth.loginWithRedirect({
      redirectUri: window.location.origin,
      ui_locales: getCurrentLanguage(),
    });
  }

  if (!client) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};
