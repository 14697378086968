import { graphql } from '@/__codegen__';
import type { GetCurrentUserLoginInfoQuery } from '@/__codegen__/graphql';

export const GetCurrentUserLoginInfo = graphql(`
    query GetCurrentUserLoginInfo {
      currentUserResolver {
        id
        email
        firstName
        lastName
        loginUserType
        loginUserCompanyType
        language
        unreadChannelCount
        unreadAssignedChannelCount
        threeWayFunctionDisabled
        loginUserTeamInfo {
          id
          name
          serviceType {
            type
            value
          }
          company {
            id
            name
          }
        }
      }
    }
`);

export type GetCurrentUserLoginInfoItem = GetCurrentUserLoginInfoQuery['currentUserResolver'];
