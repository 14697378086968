import { useLDClient } from 'launchdarkly-react-client-sdk';

import type { GetCurrentUserLoginInfoItem } from '@/shared/gql/query/GetCurrentUserLoginInfo';

import { useAuth } from './useAuth';

/**
 * A custom hook to change context of LaunchDarkly
 * @returns {{
 *  setUpLaunchDarklyUser: (currentUser: object) => void
 * }}
 */
const useLDContextChange = () => {
  const ldClient = useLDClient();

  const { getLoginUserRole, getToken } = useAuth();

  /**
   * A function to set up LaunchDarkly user
   * @param {object} currentUser
   */
  const setUpLaunchDarklyUser = (currentUser: GetCurrentUserLoginInfoItem) => {
    const companyName = currentUser.loginUserTeamInfo?.company.name;

    const ldUser = {
      kind: 'user',
      anonymous: false,
      key: currentUser.id || '',
      companyName,
      companyID: currentUser.loginUserTeamInfo?.company.id || '',
      selectedCustomerTeamId: currentUser.loginUserTeamInfo?.id || '',
      team: currentUser.loginUserTeamInfo?.name,
      userType: getLoginUserRole(),
    };

    try {
      ldClient?.identify(ldUser);
    } catch (e) {
      window.rollbar.error(e as any);
    }
  };

  const setUpLaunchDarklyStaff = async () => {
    const companyName = 'shippio';

    const ldUser = {
      kind: 'user',
      anonymous: false,
      key: await getToken(),
      companyName,
      userType: getLoginUserRole(),
    };

    try {
      ldClient?.identify(ldUser);
    } catch (e: unknown) {
      window.rollbar.error(e as any);
    }
  };

  return {
    setUpLaunchDarklyUser,
    setUpLaunchDarklyStaff,
  };
};

export default useLDContextChange;
