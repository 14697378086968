import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

import { ampli, type IdentifyProperties } from '@/__ampli__';
import type { GetCurrentUserLoginInfoItem } from '@/shared/gql/query/GetCurrentUserLoginInfo';

import { pageViewTrackingEnrichment } from './EnrichmentPlugins';

export const setUpAmplitude = () => {
  ampli.load({
    client: {
      apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY as string,
      configuration: {
        defaultTracking: true,
      },
    },
    disabled: false,
  });
  ampli.client.add(pageViewTrackingEnrichment());
  const sessionReplayTracking = sessionReplayPlugin();
  ampli.client.add(sessionReplayTracking);
};

export const setAmplitudeUser = (user: GetCurrentUserLoginInfoItem) => {
  const properties: IdentifyProperties = {
    company_id: user.loginUserTeamInfo?.company.id,
    company_name: user.loginUserTeamInfo?.company.name,
  };

  ampli.identify(user.id as string, properties);

  ampli.client.setGroup('company_id', user.loginUserTeamInfo?.company.id as string);
  ampli.client.setGroup('company_name', user.loginUserTeamInfo?.company.name as string);
  ampli.client.setGroup('selected_team_id', user.loginUserTeamInfo?.id as string);
};
