import type { AnyAction } from '@reduxjs/toolkit';

import { STORAGE_CHAT_ONLY_UNREAD } from '@/AppMain/utils/constants/storage';

import * as types from './types';

export type ChatState = {
  isChatOpen: boolean,
  isChatListOpen: boolean,
  showOnlyUnread: boolean,
}

const initial: ChatState = {
  isChatOpen: false,
  isChatListOpen: false,
  // hydrate from local storage
  showOnlyUnread: !!localStorage.getItem(STORAGE_CHAT_ONLY_UNREAD),
};

export const chat = (state = initial, action: AnyAction): ChatState => {
  switch (action.type) {
    case types.CHAT_OPEN_EDIT:
    case types.CHAT_LIST_OPEN_EDIT:
      return {
        ...state,
        ...action.payload,
      };

    case types.CHAT_SHOW_ONLY_UNREAD:

      if (action.payload.showOnlyUnread) {
        localStorage.setItem(STORAGE_CHAT_ONLY_UNREAD, 'true');
      } else {
        localStorage.removeItem(STORAGE_CHAT_ONLY_UNREAD);
      }

      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
