// Add user info to rollbar payload so that we can track more easily
export const configureRollbar = (currentUser) => {
  if (window.rollbar) {
    window.rollbar.configure({
      payload: {
        person: {
          id: currentUser.id,
          email: currentUser.email,
          username: currentUser.name,
        },
        environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
      },
    });
  }
};
