import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Rollbar from 'rollbar';

import { LoginUserTypeEnum } from '@/__codegen__/graphql';
import i18n from '@/AppMain/i18n';
import { setAmplitudeUser } from '@/lib/amplitude';
import { setUserDatadog } from '@/lib/datadog';
import { setGTMUser } from '@/lib/gtm';
import { configureRollbar } from '@/lib/rollbar';

import { GetCurrentUserLoginInfo } from '../gql/query/GetCurrentUserLoginInfo';
import type { AppState } from '../redux/AppState';
import { setCurrentUser } from '../redux/currentUserSlice';
import { STAFF_SERVICE_TYPE_VALUE } from '../utils/auth/auth';
import useLDContextChange from '../utils/hooks/useLDContextChange';

type Props = {
  children: React.ReactNode;
}

// TODO: Integrate the process of getting CurrentUser with the one done by AppAuthenticated.
export const CurrentUserProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.user);
  const { setUpLaunchDarklyUser, setUpLaunchDarklyStaff } = useLDContextChange();

  useEffect(() => {
    (window as any).rollbar = null;
    if (!window.rollbar) {
      // Rollbar config is here
      const rollbar = new Rollbar({
        accessToken: process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        enabled: process.env.NODE_ENV === 'production',
        scrubFields: ['X-Auth-Token'],
        hostSafeList: [
          'app2.shippio.io',
          'staging.app2.shippio.io',
          'demo.app2.shippio.io',
          'app.shippio.jp',
          'app.stg.shippio.jp',
          'demo-app.shippio.jp',
        ],
        payload: {
          environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
        },
        checkIgnore: (_isUncaught, args) => {
          // Code here to determine whether or not to send the payload
          // to the Rollbar API
          // return true to ignore the payload
          if ((args[1] as any)?.name === 'ChunkLoadError') {
            return true;
          }

          return false;
        },
      });
      window.rollbar = rollbar;
    }

    return () => {
      (window as any).rollbar = null;
    };
  }, []);

  useQuery(GetCurrentUserLoginInfo, {
    onCompleted: (data) => {
      const currentUser = data.currentUserResolver;

      const { company, ...team } = currentUser.loginUserTeamInfo
        ? currentUser.loginUserTeamInfo
        : { // for Shippio Staff
          id: '',
          name: 'shippioStaff',
          company: { id: '', name: 'shippio' },
          serviceType: {
            type: 'Staff',
            value: STAFF_SERVICE_TYPE_VALUE,
          },
        };

      dispatch(setCurrentUser({
        id: currentUser.id,
        email: currentUser.email,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        language: currentUser.language,
        loginUserType: currentUser.loginUserType ?? null,
        loginUserCompanyType: currentUser.loginUserCompanyType ?? null,
        threeWayFunctionDisabled: currentUser.threeWayFunctionDisabled,
        unreadChannelCount: currentUser.unreadChannelCount,
        unreadAssignedChannelCount: currentUser.unreadAssignedChannelCount,
        company,
        team,
        teamId: team.id,
      }));

      i18n.changeLanguage(currentUser?.language || 'ja');
      configureRollbar(currentUser);
      setUserDatadog(currentUser.id);
      setGTMUser(currentUser);
      setAmplitudeUser(currentUser);
      if (currentUser.loginUserType === LoginUserTypeEnum.Staff) {
        setUpLaunchDarklyStaff();
      } else {
        setUpLaunchDarklyUser(currentUser);
      }
    },
  });

  if (user.loginUserType === null || user.loginUserCompanyType === null) {
    return null;
  }

  return <>{children}</>;
};
